@import "src/assets/styles";

.services_details_container {
  border: 1px solid $outline;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem 0;
}

.modal_body {
  padding: 2rem;

  .modal_flex_group {
    display: flex;
    flex-wrap: wrap
  }

  span {
    font-weight: 500;
  }

  .modal_img {
    min-width: 336px;
    max-height: 336px;
    margin-left: 75px;
  }
}
@import "../../../../assets/styles/variables";

.main_container {
  padding: 2.5rem 2.5rem 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .page_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      margin: 0;
      font-size: 2.1rem;
      font-weight: 500;
    }

    svg {
      color: $primary_200;
    }
  }

  .tab_bar {
    width: 100%;
    margin-top: 2.5rem;
  }
}

.right_sidebar {
  position: sticky;
  top: 0;
  height: 100%;
  width: 340px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 97vh;
    background-color: $outline;
  }

  :global .MuiDivider-root {
    margin-block: 0.3rem;
  }

  .right_sidebar_content {
    width: 300px;
    padding: 1rem;
  }

  .approval_section {
    padding-inline: 0.8rem;
  }

  @media (max-width: 1740px) {
    width: 300px;

    .right_sidebar_content {
      width: 270px;
    }

  }
}
@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.container {


  :global .MuiBreadcrumbs-separator {
    svg {
      color: $black_medium_emphasis !important;
    }
  }

  :global li:nth-last-of-type(2) {

    svg {
      color: $primary_700 !important;
    }
  }


  .selected_item {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: $primary_700;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      letter-spacing: 0.5px;
    }

    svg {
      margin-right: 0.3rem;
      color: $primary_700;
    }
  }

  .active_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include underline-animation($black_medium_emphasis);


    p {
      color: $black_medium_emphasis;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      letter-spacing: 0.5px;
    }

    svg {
      margin-right: 0.3rem;
      color: $black_medium_emphasis;
    }

  }
}
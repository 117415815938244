.container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .rows_per_page {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
}
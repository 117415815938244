@import "../../../../../../assets/styles/variables";

.top_bar {
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey_medium;
  background-color: $white;
}
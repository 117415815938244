@import "../../../../../assets/styles/variables";

.top_bar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .breadcrumbs {
    position: absolute;
    left: 22px;
    top: 8px;

    @media (max-width: 910px) {
      position: relative;
      left: 0;
    }
  }

  .stepper_container {
    width: 400px;
    @media (max-width: 910px) {
      width: 100%;
    }
  }
}



.wrapper {
  display: flex;

  .container {
    margin: 1rem auto;
    height: 100%;
    width: 1000px;
  }
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .page_container_back_button {
    position: fixed;
    left: 4rem;
    top: 4rem;
    z-index: 10;
  }

  .inner_container {
    max-width: 345px;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .logo {
      margin-left: -12px;
      margin-top: 4rem;
    }
  }

  .name_fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
}



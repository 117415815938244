@import "../../../../../assets/styles/variables";

.main_container {
  position: sticky;
  top: 0;
  height: 100%;
  width: 340px;

  @media (max-width: 1740px) {
    width: 300px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 97vh;
    background-color: $outline;
  }
}

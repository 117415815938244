@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/mixins";

.container {
  @include transition();
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid $outline;
  margin: 0.8rem 0.3rem;
  padding: 1.5rem 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: $primary_50;
  }

  p {
    margin: 0;
  }

  .left_section {
    display: inline-block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 60%;

    p {
      margin-bottom: 0.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      color: $primary_700;
    }

    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      color: $black_medium_emphasis;
    }

    .payment_description {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p:first-of-type {
        margin-right: 0.2rem;
        font-weight: 400;
        font-size: 14px;
      }

      p:last-of-type {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .active {
    border-radius: 20px;
    padding: 0.4rem 1rem;
    background-color: $green;
    color: $white;
    font-size: 14px;
  }

  .under_review {
    background-color: $orange-background;
    padding: 0.4rem 1rem;
    border-radius: 20px;
    font-size: 14px;
  }

  .un_submitted {
    background-color: $grey_medium;
    padding: 0.4rem 1rem;
    border-radius: 20px;
    font-size: 14px;
  }

  button {
    font-size: 14px;

  }
}
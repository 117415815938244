@import "variables";
@import "mixins";

// General Styling
html {
  body {
    margin: 0;
    background-color: $white;
    font-family: "Rubik", "Kollektif", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .MuiTooltip-tooltip {
    background-color: $black;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .padding_left {
    padding-left: 1rem;
  }

  .margin_right {
    margin-right: 1rem;
  }

  .flex_row_space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex_end_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary_700;
  }

  .high_emphasis {
    color: $black_high_emphasis;
  }

  .body2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #687683;
    padding: 0;
    margin: 0;
  }

  .overline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.07em;
    color: rgba(1, 28, 48, 0.6);
    text-transform: uppercase;
  }

  .overline_bold {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.07em;
    color: #23394A;
    text-transform: uppercase;
  }
}

html {
  .green {
    color: $green;
  }
}

// Popovers
html {
  .module-section-popover {
    .popover_option {
      @include transition();
      cursor: pointer;
      padding: 0.8rem;

      p {
        margin: 0 0 0 0.2rem;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: $primary_50;
      }
    }
  }
}

// Popovers
html {
  .module-section-popover {
    .popover_option {
      @include transition();
      cursor: pointer;
      padding: 0.8rem;

      p {
        margin: 0 0 0 0.2rem;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: $primary_50;
      }
    }
  }
}

// Components
html {
  .sample-card {
    @include transition();
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    border: 1px solid $black;
    border-radius: 10px;
    background-color: $blue;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: $black;
    }

    .header {
      font-weight: 500;
    }

    .body {
      font-weight: 400;
    }
  }

  .styled-list {
    $background: var(--background);
    max-width: 475px !important;
    padding: 5px !important;
    background: $background;
    min-height: 30rem;
    border-radius: 10px;
  }

  .admin-avatar {
    $hoverColor: var(--hover-color);

    &:hover {
      cursor: pointer;
    }

    &.has-hover-color {
      &:hover {
        background-color: $hoverColor !important;
      }
    }
  }

  .search-drop-down-wrapper {
    width: 500px;
    height: 400px;
    margin-bottom: 1rem;
    overflow: hidden !important;
    color: $grey_dark;
  }

  .secondary-sidebar {
    padding-right: 1.5rem;
    margin-right: 0;
    border-left: 1px solid $outline;
    width: 275px;
    min-height: calc(100vh - $header-height);
  }

  .data-table-status-container {
    display: inline-block;
    border: 1px solid $outline;
    border-radius: 100px;
    padding: 0.3rem 0.7rem;

    .dot {
      $color: var(--background-color);
      $borderColor: var(--border-color);
      height: 15px;
      width: 15px;
      background-color: $color;
      border-radius: 50%;
      display: inline-block;
      border: 2px solid $borderColor;
      margin-right: 10px;
    }
  }

  .discount-alert-container {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: $green_background;
    padding: 1rem;

    p {
      font-weight: 400;
      font-size: 14px;
      margin: 0.2rem 0 0;
    }

    svg {
      margin-right: 0.4rem;
      color: $green;
    }

    &.has-margin-bottom {
      margin-bottom: 0.5rem;
    }
  }

  .divider {
    border-top: 1px solid $outline;
    margin: 1rem 0;
  }

  .divider_bold {
    border-top: 2px solid $primary_700;
    margin: 1rem 0;
  }
}

// Screens
html {
  .sample-screen {
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sample-card {
      margin-bottom: 1rem;
    }
  }

  .dashboard-screen {
    display: flex;
    margin-top: -1rem;

    .dashboard-screen-content {
      margin-inline: auto;
      margin-top: 3rem;
      width: 1000px;
    }
  }

  .users-screen {
    display: flex;

    .users-screen-content {
      margin: 1rem auto;
      height: 100%;
      width: 1000px;
    }
  }
}

// Dialogs
html {

}

@import "src/assets/styles";
.service_row_container {

  .row {
    display: flex;
    align-items: center;
  }

  .item {
    margin-right: 2.5rem;
  }

  .label {
    color: $black_medium_emphasis;
    font-size: 0.75rem;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .value {
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
    font-size: 1rem;
    color: $primary_700;
  }

  .original_background {
    border-radius: 10px;
    background-color: $primary_50;
    padding: 1rem;
  }
}
@import "src/assets/styles";

$containerPadding: 1rem 1.5rem;

//TODO: refactor below class into the detail component's own scss
.details_container {
  border-radius: 10px;
  border: 1px solid $outline;
  padding: $containerPadding;

  .flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

.right_column {
  padding: $containerPadding;
}

.bread_crumbs {
  padding: 0.5rem 0 0.5rem 1.2rem;
}
//TODO: implement the theme color variables
$white: white;
$white_high_emphasis: rgba(255, 255, 255, 0.95);

$black: black;
$blue: #0C55B5;
$red: #F00F3C;
$red-background: #FEF3F5;
$orange-background: #FCE8D0;
$yellow-background: #FEF4C0;

$green: #4AA01F;
$green_background: #E6FAE0;

//Primary
$primary_50: #F2F6FB;
$primary_200: #0C55B5;
$primary_400: #053C70;
$primary_500: #01304E;
$primary_700: #011C30;

$grey_dark: #5A5A5A;
$grey_medium: #E8E8E8;
$grey_light: #FAFAFA;

$outline: #CCD6DC;
$blueBg: #E5EDF7;

$black_medium_emphasis: #011C3099;
$black_low_emphasis: #011C3061;
$black_high_emphasis: rgba(1, 28, 48, 0.87);

$grey-medium: #E8E8E8;
$background-grey: #F5F5F5;


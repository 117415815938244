@import "../../../../assets/styles/variables";

.loading_component_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
  width: 100%;

  img {
    width: 7rem;
  }
}

.container {
  padding-top: 1.2rem;
  padding-bottom: 5rem;
  height: 100%;
  display: flex;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  flex-grow: 1;
}

@import "src/assets/styles/index";

.statement_balance_card_container {
  margin-top: 4.8rem;
  padding: 0.5rem 1.5rem 1rem;
  background: $white_high_emphasis;
  border: 1px solid $outline;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

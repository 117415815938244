@import "../../../../assets/styles/variables/index";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $background-grey;
  min-height: 100%;
}

.top_bar {
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey_medium;
  background-color: $white;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.sub_title {
  margin-top: 0;
}

